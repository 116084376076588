<template>
  <div class="row h-24 mb-4">
    <div class="col-sm-6 col-md-4 col-lg-4" data-aos="zoom-in">
      <div class="card shadow bg-white rounded">
        <div class="card-body">
          <div class="heading">
            <h3 class="card-title">Add Device on platform</h3>
          </div>
          <p class="card-text">
            <!-- <pdf class="h-25" src="/files/AdminPanelAddDevice.pdf" :page="1"></pdf> -->
          </p>
          <router-link to="/help/adddevice">
            <button class="btn btn-primary">Details</button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4" data-aos="zoom-in">
      <div class="card shadow bg-white rounded">
        <div class="card-body">
          <div class="heading">
            <h3 class="card-title">Nbiot Device</h3>
          </div>

          <p class="card-text">
            <!-- <pdf class="h-25" src="/files/Mqtt.pdf" :page="3"></pdf> -->
          </p>
          <router-link to="/help/nbiot">
            <button class="btn btn-primary">Details</button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4" data-aos="zoom-in">
      <div class="card shadow bg-white rounded">
        <div class="card-body">
          <div class="heading">
            <h3 class="card-title">Add Bins on platform</h3>
          </div>

          <p class="card-text">
            <!-- <pdf src="/files/AdminPanelBins.pdf" :page="1"></pdf> -->
          </p>
          <router-link to="/help/addbins">
            <button class="btn btn-primary">Details</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <div class="row h-50">
    <div class="col-sm-6 col-md-4 col-lg-4" data-aos="zoom-in">
      <div class="card shadow bg-white rounded">
        <div class="card-body">
          <div class="heading">
            <h3 class="card-title">Roles Permission</h3>
          </div>

          <p class="card-text">
            <!-- <pdf class="h-25" src="/files/Permissions.pdf" :page="1"></pdf> -->
          </p>
          <router-link to="/help/roles">
            <button class="btn btn-primary">Details</button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 h-50" data-aos="zoom-in">
      <div class="card shadow bg-white rounded">
        <div class="card-body">
          <div class="heading">
            <h3 class="card-title">Admin Panel</h3>
          </div>

          <p class="card-text">
            <!-- <pdf src="/files/AdminPanel.pdf" :page="1"></pdf> -->
          </p>
          <router-link to="/help/adminpanel">
            <button class="btn btn-primary">Details</button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-4 h-50" data-aos="zoom-in">
      <div class="card shadow bg-white rounded">
        <div class="card-body">
          <div class="heading">
            <h3 class="card-title">Daizy Webhook Document</h3>
          </div>

          <p class="card-text">
            <!-- <pdf src="/files/AdminPanel.pdf" :page="1"></pdf> -->
          </p>
          <router-link to="/help/daizy">
            <button class="btn btn-primary">Details</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import pdf from "vue3-pdf";
import { onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
export default {
  components: {
    // pdf,
  },
  setup() {
    onMounted(async () => {
      setCurrentPageBreadcrumbs("Docs", [""]);
    });
  },
};
</script>
